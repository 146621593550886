import {Controller} from "stimulus"
import $ from 'jquery';
import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";

export default class extends Controller {

    connect() {
        flatpickr("#disable_date_from", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            minDate: new Date(),
            locale: Spanish
        });
        flatpickr("#from", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            locale: Spanish
        });
        flatpickr("#from_np", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            minDate: new Date(),
            locale: Spanish
        });
        flatpickr("#disable_date_to", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            minDate: new Date(),
            locale: Spanish
        });
        flatpickr("#to", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            locale: Spanish
        });
        flatpickr("#to_np", {
            altInput: true,
            altFormat: "d-m-Y",
            dateFormat: "Y-m-d",
            minDate: new Date(),
            locale: Spanish
        });
    }

    setDataTo(e) {
        let input_target = $('#' + e.target.dataset.target)[0];
        let value = e.target.value;
        let type = e.target.dataset.type
        let hours = '';

        switch (type) {
            case 'from':
                hours = '00:00:00'
                break;
            case 'to':
                hours = '23:59:59'
                break;

        }

        input_target.value = `${value} ${hours}`;
    }
}