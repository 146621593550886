import { Controller } from "stimulus"
import $ from 'jquery';

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import select2 from "select2"
// require('block-ui/jquery.blockUI')
export default class extends Controller {

    connect() {
        $('.c2ec_select').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción"
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });

        $('.c2ec_select_table_refresh').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción"
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        }).on('change', function () {
            $('#table').bootstrapTable('refresh');
        });

        $('.c2ec_select_table_refresh_with_clear').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
            allowClear: true
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        }).on('change', function () {
            $('#table').bootstrapTable('refresh');
        });

        $('.c2ec_select_multiple').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
            multiple: true
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });

        $('.c2ec_select_with_clear').select2({
            theme: "bootstrap",
            language: "es",
            placeholder: "Seleccione una opción",
            allowClear: true
        }).on('select2:select', function () {
            let event = new Event('change', {bubbles: true})
            this.dispatchEvent(event);
        });

        //Agencies select

        $('#agency_select').select2({
            theme: "bootstrap",
            placeholder: 'Seleccione una o varias agencias',
            multiple: true,
            ajax: {
                url: `${location.origin}/admin/user_types/data`,
                dataType: "json",
                language: "es",
                data: function (params) {
                    return {
                        search: params.term,
                        page: params.page || 1
                    }

                },
                type: 'GET',
                processResults: function (data, params) {
                    let result = [];
                    params.page = params.page || 1;

                    data.rows.map((elem, key) => {
                        result.push({
                            id: elem.id,
                            text: elem.text
                        })
                    })

                    if (data.total > 10) {

                        if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                            return {
                                results: result, "pagination": {
                                    "more": true
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }

                    } else {
                        return {
                            results: result, "pagination": {
                                "more": false
                            }
                        }
                    }
                }
            }
        })
        $('#agency_select_single_selection').select2({
            theme: "bootstrap",
            placeholder: 'Seleccione una o varias agencias',
            ajax: {
                url: `${location.origin}/admin/user_types/data`,
                dataType: "json",
                language: "es",
                data: function (params) {
                    return {
                        search: params.term,
                        page: params.page || 1
                    }

                },
                type: 'GET',
                processResults: function (data, params) {
                    let result = [];
                    params.page = params.page || 1;

                    data.rows.map((elem, key) => {
                        result.push({
                            id: elem.id,
                            text: elem.text
                        })
                    })

                    if (data.total > 10) {

                        if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                            return {
                                results: result, "pagination": {
                                    "more": true
                                }
                            }
                        } else {
                            return {
                                results: result, "pagination": {
                                    "more": false
                                }
                            }
                        }

                    } else {
                        return {
                            results: result, "pagination": {
                                "more": false
                            }
                        }
                    }
                }
            }
        })

    }
}