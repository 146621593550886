import {Controller} from "stimulus"
import $ from 'jquery';
import toastr from 'toastr/toastr'
import bootbox from 'bootbox/bootbox';
import 'toastr/toastr.scss'
import popper from '@popperjs/core'
import {setSessionVar} from "./sessionStorage_controller";
import {set_locale} from "./localize_controller";
import {get} from "@rails/request.js";

export let obtenerIdCheckDatatable = () => {
    let array = []
    let input = $('#table').find('input[type="checkbox"]');
    input.map(elem => {
        let check = $(input[elem]);
        if (check.is(':checked')) {
            if (check.val() !== 'on') {
                array.push(check.val());
            }
        }

    })
    return array;
}

let contar_caracteres = (max, counter, elem) => {

    //Numero de caracteres permitidos
    var total = max;
    var valor = document.getElementById(elem);
    var respuesta = document.getElementById(counter);
    var cantidad = valor.value.length;

    if (parseInt(cantidad) >= parseInt(total)) {
        valor.value = valor.value.slice(0, parseInt(max));
    }

    setTimeout(function (e) {
        document.getElementById(counter).innerHTML = cantidad + '/' + total;
        if (cantidad >= total) {
            respuesta.style.background = "#d43f34";
        } else {
            respuesta.style.background = "#6a6866";
        }
    }, 10);

}

let send_security_data = (data, method) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/admin/security/edit`,
        data: data,
        type: method,
        success: function (result) {
            resolve(result);
        }
    })
})
export default class extends Controller {
    initialize() {
        $('[data-toggle="tooltip"]').tooltip()
        //SIDEBAR
        $('#input-search').on('keyup', e => {
            let element_searchable = $(`${e.target.dataset.search}`).find('li.nav-item');
            let hidde_element = $(`${e.target.dataset.hide}`);
            if (hidde_element.length > 0) {
                if (e.target.value.length > 0) {
                    hidde_element.map((index, elem) => {
                        elem.classList.add('ocultar');
                        elem.classList.remove('d-flex');
                        elem.classList.remove('mt-4');
                        elem.classList.remove('mb-1');

                    })
                } else {
                    hidde_element.map((index, elem) => {
                        elem.classList.remove('ocultar');
                        elem.classList.add('d-flex');
                        elem.classList.add('mt-4');
                        elem.classList.add('mb-1');
                    })
                }
            }

            if (element_searchable.length > 0) {
                element_searchable.map((index, elem) => {
                    if (elem.outerText.toLowerCase().includes(e.target.value.toLowerCase())) {
                        elem.classList.remove('ocultar');
                        elem.parentNode.classList.add('mb-2')
                    } else {
                        elem.classList.add('ocultar');
                        elem.parentNode.classList.remove('mb-2')
                    }
                })
            }
        })


        //PAGES
        if (location.pathname.includes('admin/pages')) {
            let external_url = $('#page_isExternaUrl');
            if (external_url !== undefined && external_url !== null) {
                let fields = document.getElementById('fields');
                let thumbnails_ni = document.getElementById('thumbnails-no-image');
                let thumbnails_image = document.getElementById('thumbnails-image');

                if (external_url.is(':checked')) {
                    fields.style.display = 'none';
                    thumbnails_ni.style.display = 'block';
                    thumbnails_image.style.display = 'none';
                }
            }

            let page_section_id = $('#page_section_id');
            if (page_section_id.length > 0 || true) {
                if (page_section_id.val() !== undefined) {
                    $.ajax({
                        url: `${location.origin}/admin/sections/${page_section_id.val()}.json`,
                        data: {format: 'json'},
                        type: 'get',
                        success: function (data) {
                            // console.log(data);
                            if ((data.typesection === 'presentation_with_image_left' || data.typesection === 'presentation-whit-image-right') && !external_url.is(':checked')) {

                                document.getElementById('page_icon').style.display = 'inline'
                            }
                        }
                    })
                }
            }

        }


        //Notification PUSH
        if (location.pathname.includes('admin/notification_pushes')) {
            if (location.pathname.includes('new')) {
                let check_box_all = document.getElementById("notification_push_notify_all");
                check_box_all.checked = true;
            }

        }

        //OFICINAS EDIT MAP
        if (location.pathname.includes('admin/offices/')) {
            let values = $('#product_selected');
            let product_type = $('#product_type');
            product_type.select2({
                theme: 'bootstrap',
                placeholder: 'Seleccione 1 o varios tipos de productos',
                multiple: true
            })

            if (values.length > 0) {
                values = values[0].dataset.products;
                if (!values.isEmpty()) {
                    values = values.split(',');
                }

                product_type.val(values);
                product_type.trigger('change');
            }
        }

        if (document.getElementById('setSecurityParams') !== null) {
            document.getElementById('setSecurityParams').addEventListener('click', () => {
                send_security_data({}, 'GET').then(result => {
                    bootbox.dialog({
                        message: `<form id="rack" name="rack">
                                   
                                         <div class="form-group mb-2">
                                         <label for="max_retry">Cantidad de intentos</label>
                                             <input id="max_retry" name="max_retry" type="number" value="${result.rack.max_retry}" min="1" max="15" step="1" class="form-control">
</div>
                                         <div class="form-group mb-2">
                                         <label for="find_time">Tiempo para bloquear por cantidad de intentos</label>
                                             <input id="find_time" name="find_time" type="number" value="${result.rack.find_time}" min="1" class="form-control">
                                             <span class="text-muted " style="font-size: .8em">El tiempo se expresa en minutos</span>
</div>
                                         <div class="form-group mb-2">
                                         <label for="ban_time">Tiempo de baneo</label>
                                             <input id="ban_time" name="ban_time" type="number" value="${result.rack.ban_time}" min="1"  class="form-control">
                                             <span class="text-muted " style="font-size: .8em">El tiempo se expresa en horas</span>
</div>
                                             
                                      
                                    
                                </form>`,
                        buttons: {
                            ok: {
                                label: 'Guardar',
                                callback: function (e) {
                                    let send_data = {
                                        rack: {
                                            max_retry: document.getElementById('max_retry').value,
                                            find_time: document.getElementById('find_time').value,
                                            ban_time: document.getElementById('ban_time').value
                                        }
                                    }

                                    send_security_data(send_data, 'POST').then(r => {
                                        location.reload();
                                    })


                                }
                            }
                        }
                    })
                })
            })
        }


    }

    connect() {
        $(".sidebar-dropdown > a").click(function () {
            $(".sidebar-submenu").slideUp(200);
            if (
                $(this)
                    .parent()
                    .hasClass("active")
            ) {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .parent()
                    .removeClass("active");
            } else {
                $(".sidebar-dropdown").removeClass("active");
                $(this)
                    .next(".sidebar-submenu")
                    .slideDown(200);
                $(this)
                    .parent()
                    .addClass("active");
            }
        });

        $("#close-sidebar").click(function (e) {
            $(".pages-wrapper").removeClass("toggled");
        });
        $("#show-sidebar").click(function () {
            $(".pages-wrapper").addClass("toggled");
        });

        //    CAMBIOS EN LOS SELECT2 DE LA ADMINISTRACION
        $('#preference_behavior').on('change', function (e) {
            let valor = $(e.target).select2('data')[0].text;
            let elem_unblock = $('#type_' + valor);
            if (elem_unblock !== undefined) {
                elem_unblock.removeClass('ocultar').on('change', function (e) {
                    let other_valor = $(e.target).select2('data')[0].text;
                    let other_elemt_unblock = $('#type_' + other_valor);
                    if (other_elemt_unblock !== undefined) {
                        other_elemt_unblock.removeClass('ocultar');
                    }
                });
            }

        });

        //PREFERENCIAS

        $('#preference_has_slider').on('change', function (e) {
            let container_group_slider = $('#container-group-slider');
            let group_slider_clone = $('#group_slider_clone');
            if (e.target.checked) {
                container_group_slider[0].classList.remove('ocultar');
            } else {
                container_group_slider[0].classList.add('ocultar');
                group_slider_clone[0].innerHTML = '';
            }
        })

        $('#preference_key_id').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            if ($(e.target).select2('data')[0].text.includes('social')) {
                behavior = 'social';
            } else {
                behavior = 'general';
            }

            $('#preference_behavior')[0].value = behavior;
        })

        //SECTION
        $('#section_typesection').select2({theme: "bootstrap"}).on('change', function (e) {
            let behavior = ''
            let thumbnails_banner_pic = document.getElementById('thumbnail-banner-image_pic');
            let thumbnails_banner_no_pic = document.getElementById('thumbnail-banner-image_no_pic');
            let thumbnails_banner = document.getElementById('thumbnail-banner-image');
            let thumbnails = document.getElementById('thumbnail-image');

            if ($(e.target).select2('data')[0].text.includes('footer')) {
                behavior = 'footer';
                thumbnails_banner_no_pic.style.display = 'block';
                thumbnails_banner_pic.style.display = 'none';
                thumbnails_banner_pic.style.display = 'none';
            } else {
                behavior = 'body';
                thumbnails_banner_no_pic.style.display = 'none';
                thumbnails_banner_pic.style.display = 'block';
                if ($(e.target).select2('data')[0].text.includes('imagen')) {
                    thumbnails.style.display = 'block';
                    thumbnails_banner.style.display = 'block';
                } else {
                    thumbnails.style.display = 'none';
                    thumbnails_banner.style.display = 'block';
                }
            }


            $('#section_destination')[0].value = behavior;
        })

        //PAGES
        let page_external = $('#page_isExternaUrl');
        if (page_external !== undefined && page_external !== null) {

            let fields = document.getElementById('fields');
            let access_url = document.getElementById('page_access_url');
            let acces_url_value = '';
            let page_icon = document.getElementById('page_icon');
            if (access_url !== undefined && access_url !== null) {
                acces_url_value = access_url.value;
            }
            let thumbnails_ni = document.getElementById('thumbnails-no-image');
            let thumbnails_image = document.getElementById('thumbnails-image');

            page_external.on('change', function (e) {
                if ($(e.target).is(':checked')) {
                    access_url.value = 'https://';
                    fields.style.display = 'none';
                    thumbnails_ni.style.display = 'block';
                    thumbnails_image.style.display = 'none';
                    page_icon.style.display = 'none';
                } else {
                    page_icon.style.display = 'inline';
                    access_url.value = acces_url_value;
                    fields.style.display = 'block';
                    thumbnails_ni.style.display = 'none';
                    thumbnails_image.style.display = 'block';
                }
            })
        }

        let page_product = $('#page_product').select2({
            placeholder: 'Seleccione el tipo de formulario a mostrar',
            allowClear: true,
            ajax: {
                url: `${location.origin}/es/admin/product_types/data`,
                dataType: "json",
                language: "es",
                theme: 'bootstrap',
                data: function (params) {
                    return {
                        search: params.term,
                        page: params.page || 1
                    }

                },
                type: 'GET',
                processResults: function (data, params) {
                    let result = [];
                    params.page = params.page || 1;

                    data.rows.map((elem, key) => {
                        result.push({
                            id: elem.text,
                            text: elem.name
                        })
                    })

                    if (data.total > 10) {

                        if ((parseInt(data.total) - (10 * parseInt(params.page))) > 1) {
                            return {
                                results: result,
                                "pagination": {
                                    "more": true
                                }
                            }
                        } else {
                            return {
                                results: result,
                                "pagination": {
                                    "more": false
                                }
                            }
                        }

                    } else {
                        return {
                            results: result,
                            "pagination": {
                                "more": false
                            }
                        }
                    }
                }
            }
        })

        $('#show_product_type').on('change', (e) => {
            let typo = $('#select_type_producto');
            if (e.target.checked) {
                typo[0].classList.remove('ocultar')
            } else {
                typo[0].classList.add('ocultar')
            }
        })

        //NOTIFICATION PUSH
        let user_check = $('#notification_push_users_notification');
        if (user_check !== undefined && user_check !== null) {
            let users_select = document.getElementById('users_select');
            $('#notification_push_notify_all').on('change', function (e) {

                if ($(e.target).is(':checked')) {
                    users_select.style.display = 'none';
                } else {
                    users_select.style.display = 'block';
                }
            })
        }

        /* Metodo para obtener los productos de la agencia de un dealer */
        $(`#disable_date_dealer_id`).on('select2:unselecting', function () {
            get(`${location.origin}/admin/get_products_data_for_agency_the_dealer`, {
                query: {
                    dealer: '',
                    target: 'disable_date_product_id'
                },
                responseKind: "turbo-stream"
            }).then()
        })
        $(`#change_season_dealer_id`).on('select2:unselecting', function () {
            get(`${location.origin}/admin/get_products_data_for_agency_the_dealer`, {
                query: {
                    dealer: '',
                    target: 'change_season_product_id'
                },
                responseKind: "turbo-stream"
            }).then()
        })

        /* Para Confirmar la creación de la facturación */
        $('#obtener_factura_form').on('submit', function(event) {
            event.preventDefault();

            const context = this

            bootbox.confirm({
                message: "¿Seguro de querer obtener la factura? Revise el reporte para mayor seguridad, si lo desea.",
                buttons: {
                    confirm: {
                        label: 'Si'
                    },
                    cancel: {
                        label: 'No'
                    }
                },
                callback: function(result) {
                    if(result) {
                        // Envía el formulario
                        context.submit();
                    }
                }
            });
        });
    }

    importData(e) {

        let url_action = e.target.dataset.url;

        if (url_action === undefined) {
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let url = location.origin + '/' + url_action;
        $.ajax({
            url: url,
            data: {format: 'json'},
            type: 'GET',
            success: function (data) {
                if (data.success) {
                    toastr.success(`${data.msg}`);
                    // $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`${data.msg}`);
                    // $('#table').bootstrapTable('refresh');
                }

            }
        })
    }
    importDataGuajirito(e) {

        let url_action = e.target.dataset.url;

        if (url_action === undefined) {
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let url = location.origin + '/' + url_action;
        $.ajax({
            url: url,
            data: {format: 'json'},
            type: 'GET',
            success: function (data) {
                if (data.success) {
                    toastr.success(`${data.msg}`);
                    // $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`${data.msg}`);
                    // $('#table').bootstrapTable('refresh');
                }

            }
        })
    }

    setActive(e) {
        let url = location.origin + '/' + e.target.dataset.url;
        $.ajax({
            url: url,
            data: {active: e.target.dataset.active},
            type: 'POST',
            success: function (data) {
                if (data.success) {
                    toastr.success(`${data.msg}`);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(`${data.msg}`);
                }

            }
        })
    }

    cloneSeason(e) {
        let ids = obtenerIdCheckDatatable();

        if(e.target.dataset.id !== undefined){
             ids = [e.target.dataset.id];
        }
       if(ids.length > 0){
           bootbox.dialog({
               title: `Clonar ${ids.length} temporada(s)`,
               message: `<div class="d-flex flex-column" data-controller="select2">
                                <div class="col-12">
                                    <label for="agency_select">Agencias</label>
                                    <select type="text" id="agency_select" class="form-control"></select>
                                    <span class="helper-text" style="font-size: .8em;">Seleccione una o varias agencias a las cuales quiere insertarle esta temporada que se esta clonando.</span>
                                </div>
                        </div>`,
               size: 'large',
               buttons: {
                   cancel: {
                       label: "Cancelar",
                       className: 'btn-secondary'
                   },
                   ok: {
                       label: "Clonar",
                       className: 'btn-success',
                       callback: function (e) {
                           let id = $('#revolving_f');
                           let agency_select = $('#agency_select').val();
                           if(agency_select.length > 0){
                               $.ajax({
                                   url: `${location.origin}/admin/change_seasons/clone`,
                                   data: {
                                       seasons_id: ids,
                                       agencies_id: agency_select,

                                   },
                                   type: 'POST',
                                   success: function (data) {
                                       if (data.success) {
                                           let table = $('#table');
                                           if (table !== undefined) {
                                               table.bootstrapTable('refresh');
                                           }
                                       } else {
                                           toastr.error(`${data.message}`);
                                       }

                                   }
                               })
                           }else{
                               toastr.warning('Debe seleccionar la menos una agencia','Alerta');
                           }



                       }
                   }
               }
           })
       }else{
           toastr.info('Debe seleccionar la menos un elemento para clonar','Información');
       }

    }

    cloneblock(){

    }

    sendIdToLink(e) {
        let url_action = e.target.dataset.url;

        if (url_action === undefined) {
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + url_action;
            location.href = `${url}?id=${ids}`
        }
    }

    setBlockActive(e) {
        let url_action = e.target.dataset.url;

        if (url_action === undefined) {
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }


        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + url_action;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }

    setBlockSameHours(e) {
        let url_action = e.target.dataset.url;

        if (url_action === undefined) {
            let parent = e.target.parentNode;
            url_action = parent.dataset.url
        }

        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            let url = location.origin + '/' + url_action;
            $.ajax({
                url: url,
                data: {id: ids},
                type: 'POST',
                success: function (data) {
                    if (data.success) {
                        toastr.success(`${data.msg}`);
                        $('#table').bootstrapTable('refresh');
                    } else {
                        toastr.error(`${data.msg}`);
                    }

                }
            })
        }

    }

    sendToWhitelist(e) {
        $.ajax({
            url: `${location.origin}/admin/security/${e.target.dataset.ip}/send`,
            data: {},
            success: (result) => {
                if (result.success) {
                    toastr.success(result.msg);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(result.msg);
                }
            }
        })
    }

    sendToBlacklist(e) {
        $.ajax({
            url: `${location.origin}/admin/security/${e.target.dataset.ip}/send_black`,
            data: {},
            success: (result) => {
                if (result.success) {
                    toastr.success(result.msg);
                    $('#table').bootstrapTable('refresh');
                } else {
                    toastr.error(result.msg);
                }
            }
        })
    }

    destroyBlock(e) {
        let url_action = e.target.dataset.url;
        let ids = obtenerIdCheckDatatable();
        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {

            if (url_action === undefined) {
                let parent = e.target.parentNode;
                url_action = parent.dataset.url
            }



            bootbox.confirm(`¿Seguro de eliminar estos ${ids.length} elementos? `, (result) => {
                if (result) {
                    let url = location.origin + '/' + url_action;
                    $.ajax({
                        url: url,
                        data: {id: ids},
                        type: 'POST',
                        success: function (data) {
                            if (data.success) {
                                toastr.success(`${data.msg}`);
                                $('#table').bootstrapTable('refresh');
                            } else {
                                toastr.error(`${data.msg}`);
                            }

                        }
                    })
                }
            })

        }
    }

    linkWithFather(e) {
        let url_action = e.target.dataset.url;
        let ids = obtenerIdCheckDatatable();
        let type = ''
        if(e.target.dataset.type !== undefined){
            type = e.target.dataset.type
        }

        if (ids.length === 0) {
            toastr.info('Debe seleccionar al menos 1 elemento para continuar');
        } else {
            if (url_action === undefined) {
                let parent = e.target.parentNode;
                url_action = parent.dataset.url
            }

            bootbox.confirm(`¿Seguro de querer vincular estos ${ids.length} elementos? `, (result) => {
                if (result) {
                    let url = location.origin + '/' + url_action + '?ids=' + ids +'&type='+type;

                    window.location.replace(url)
                }
            })
        }
    }

    sendValidateUserEmail(e) {
        e.preventDefault();
        e.stopPropagation();
        $.ajax({
            url: e.target.dataset.url,
            data: {
                format: 'json',
            },
            type: 'GET',
            success: function (result) {
                if (result.success) {
                    toastr.success(result.message, result.title);
                } else {
                    toastr.error('No se ha podido enviar el correo de validación para este usuario.', 'Error en el envio del correo');
                }
            }
        })
    }

    counter_characters(e) {
        contar_caracteres(e.target.dataset.max, e.target.dataset.counter, e.target.id);
    }

    showImg(e) {
        let src = document.getElementById(e.target.dataset.value).value
        bootbox.alert({
            message: `<img src='${src}' style='width: 100%'>`,
            buttons: {
                ok: {
                    label: "<span class='fa fa-check'></span> Visto",
                    className: 'btn-info'
                }
            }
        })
    }

    revolvingFund(e) {
        let id = e.target.dataset.id;
        bootbox.dialog({
            title: `Agregar Fondo a ${e.target.dataset.name}`,
            message: `<div class="d-flex flex-column">
                                <div class="col-12">
                                    <label for="revolving_f">Fondo</label>
                                    <input type="text" id="revolving_f" value="0" class="form-control">
                                </div>
                                 <div class="col-12">
                                    <label for="revolving_f">Justificante</label>
                                    <input type="text" id="revolving_proof" class="form-control">
                                </div>
                                </div>`,
            size: 'large',
            buttons: {
                cancel: {
                    label: "Cancelar",
                    className: 'btn-secondary'
                },
                ok: {
                    label: "Agregar",
                    className: 'btn-success',
                    callback: function (e) {
                        let input = $('#revolving_f');
                        let input_proof = $('#revolving_proof');
                        $.ajax({
                            url: `${location.origin}/admin/user_types/${id}/fund`,
                            data: {
                                fund: input.val(),
                                proof: input_proof.val(),

                            },
                            type: 'GET',
                            success: function (data) {
                                if (data.success) {
                                    toastr.success(data.msg, 'Fondo revolvente actualizado')
                                    let table = $('#table');
                                    if (table !== undefined) {
                                        table.bootstrapTable('refresh');
                                    }
                                    let table_client = $('#table_client');
                                    if (table_client !== undefined) {
                                        table_client.bootstrapTable('refresh');
                                        location.reload();
                                    }
                                } else {
                                    toastr.error(data.msg, 'Fondo revolvente')
                                }

                            }
                        })

                    }
                }
            }
        })
        ;
    }

    revolvingFundRemove(e) {
        let id = e.target.dataset.id;
        bootbox.dialog({
            title: `Remover Fondo a ${e.target.dataset.name}`,
            message: `<div class="d-flex flex-column">
                                <div class="col-12">
                                    <label for="revolving_f">Fondo a remover</label>
                                    <input type="text" id="revolving_f" value="0" class="form-control">
                                </div>
                                 <div class="col-12">
                                    <label for="revolving_f">Justificante</label>
                                    <input type="text" id="revolving_proof" class="form-control">
                                </div>
                                </div>`,
            size: 'large',
            buttons: {
                cancel: {
                    label: "Cancelar",
                    className: 'btn-secondary'
                },
                ok: {
                    label: "Remover",
                    className: 'btn-danger',
                    callback: function (e) {
                        let input = $('#revolving_f');
                        let input_proof = $('#revolving_proof');
                        $.ajax({
                            url: `${location.origin}/admin/user_types/${id}/remove/fund`,
                            data: {
                                fund: input.val(),
                                proof: input_proof.val(),

                            },
                            type: 'GET',
                            success: function (data) {
                                if (data.success) {
                                    toastr.success(data.msg, ' Fondo revolvente actualizado')
                                    let table = $('#table');
                                    if (table !== undefined) {
                                        table.bootstrapTable('refresh');
                                    }
                                    let table_client = $('#table_client');
                                    if (table_client !== undefined) {
                                        table_client.bootstrapTable('refresh');
                                        location.reload();
                                    }
                                } else {
                                    toastr.error(data.msg, 'Fondo revolvente')
                                }

                            }
                        })

                    }
                }
            }
        })
        ;
    }

//  METODOS PARA EL DASHBORAD

    actionWithReservationCheck(e) {
        let action = e.target.dataset.act;
        let id = e.target.dataset.id;
        let table = e.target.dataset.table;
        $.ajax({
            url: `${location.origin}/admin/action_with_revision/${action}/${id}`,
            data: {},
            type: 'GET',
            success: function (data) {
                if (data.success) {
                    toastr.success(data.message, 'Ok');
                } else {
                    toastr.error(data.message, 'Error');
                }

                $(`#${table}`).bootstrapTable('refresh')
            }
        })
    }

    checkReservationStatus(e) {
        // console.log(e.target);
        let references = e.target.dataset.references;
        $.ajax({
            url: `${location.origin}/admin/check_reservation/${references}.json`,
            data: {
                format: 'json'
            },
            type: 'GET',
            beforeSend: function () {
                let element = $(`#check_reservation_${references}`);
                // console.log(element);
                element[0].innerHTML = `<span class="fa fa-spin fa-refresh" data-references="${element.id}"></span>`
            },
            success: function (data) {
                let element = $(`#check_reservation_${references}`);
                element[0].innerHTML = `<span class="fa fa-refresh" data-references="${element.id}"></span>`
                if (data.success) {
                    bootbox.alert(data.message);
                } else {
                    toastr.error(data.message, 'Error');
                }
            }
        })
    }

    /* Metodo para obtener los productos de la agencia de un dealer */

    getProductForAgencyDealer(e){
        let dealer = $(`#${e.target.dataset.idDealer}`)[0].value;

        get(`${location.origin}/admin/get_products_data_for_agency_the_dealer`, {
            query: {
                dealer: dealer,
                target: e.target.dataset.idProduct
            },
            responseKind: "turbo-stream"
        }).then()
    }

//     GENERAR VOUCHER QUE HAN DADO ERROR Y NO SE ESCRIBIERON
    regenerate_voucher(e){
        let dialog_user_data = bootbox.dialog({
            title: 'Inserte los datos del Voucher a Regenerar',
            message: `<p class='text-danger small'>(*) Campos obligatorios</p><br>
                    <div id="agency" class="row" data-controller="select2">
                        <div class="col-12 mb-2">
                            <label for="agency_select_single_selection">
                                Agencia <span class="text-danger">*</span>
                            </label>
                            <select id="agency_select_single_selection" class="form-control required"></select>
                        </div>
                        <div class="col-12 mb-2">
                        <label for="voucher_references">
                                No. Confirmación de reserva <span class="text-danger">*</span>
                            </label>
                         <input id="voucher_references" type="text"  class="form-control required"> 
                        </div>
                    </div>`,
            buttons: {
                cancel: {
                    label: "Cancelar",
                    className: 'btn-secondary'
                },
                ok: {
                    label: "Regenerar voucher",
                    className: 'btn-success',
                    callback: function (e) {
                        let count = 0;
                        let agency_select_single_selection = $('#agency_select_single_selection');
                        let voucher_references = $('#voucher_references');

                        if(agency_select_single_selection[0].selectedOptions.length === 0){
                            agency_select_single_selection[0].classList.add('is-invalid')
                            count = count + 1;
                        }else{
                            agency_select_single_selection[0].classList.remove('is-invalid')
                        }

                        if(voucher_references[0].value.isEmpty()){
                            voucher_references[0].classList.add('is-invalid')
                            count = count + 1;
                        }else{
                            voucher_references[0].classList.remove('is-invalid')
                        }


                        if (count > 0) {
                            return false;
                        } else {
                            toastr.info('Regenerando datos de la reserva, este proceso puede demorar algunos segundos.');
                            $.ajax({
                                url: `${location.origin}/admin/histories/regenerate`,
                                data: {
                                    admin_user_type_id: agency_select_single_selection[0].selectedOptions[0].value,
                                    voucher_references: voucher_references[0].value
                                },
                                type: 'POST'
                            })
                            $('button.bootbox-close-button').trigger('click');
                        }

                        return true;

                    }
                }
            }
        })
        setTimeout(()=>{
            $('.bootbox.modal')[0].removeAttribute('tabindex');
        },500)
        ;
    }
}