import {Controller} from "stimulus"
import $ from 'jquery';
import Swal from 'sweetalert2'
import 'sweetalert2/dist/sweetalert2.css'
import '@sweetalert2/theme-borderless/borderless.scss'


export default class extends Controller {

    connect() {
        setTimeout(() => {
            this.calcRefund();
        }, 1000);

    }

    calcRefund() {
        let price = $('#precio_total');
        let discount_input = $('#voucher_refunding');
        let discount = $('#refunding_percent');
        let total = $('#refunding_total');
        discount[0].innerText = discount_input.val();
        total[0].innerText = (parseFloat(price[0].innerText) * parseFloat(discount_input.val())) / 100;
    }

    denyVoucher(e) {
        Swal.fire({
            title: 'Denegar reserva',
            text: "¿Esta seguro que desea denegar la solicitud de esta reserva?",
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, deseo denegar la solicitud',
            cancelButtonText: 'No',
            reverseButtons: true,
            allowOutsideClick: false,
            background: `#FFFFFF`,
            backdrop: `rgba(0, 0, 0, .8)`,
            customClass: {
                loader: 'custom-loader',
                confirmButton: 'btn btn-warning',
                cancelButton: 'btn btn-secondary mr-2'
            },
            buttonsStyling: false
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    $(`#${e.target.dataset.submit}`).submit();
                }
            })
    }

    cancelVoucher(e) {
        Swal.fire({
            title: 'Cancelar reserva',
            text: "¿Esta seguro que desea cancelar esta reserva?",
            icon: 'error',
            showCancelButton: true,
            confirmButtonText: 'Si, deseo cancelar la reserva',
            cancelButtonText: 'No',
            reverseButtons: true,
            allowOutsideClick: false,
            background: `#FFFFFF`,
            backdrop: `rgba(0, 0, 0, .8)`,
            customClass: {
                loader: 'custom-loader',
                confirmButton: 'btn btn-danger',
                cancelButton: 'btn btn-secondary mr-2'
            },
            buttonsStyling: false
        })
            .then(async (result) => {
                if (result.isConfirmed) {
                    $(`#${e.target.dataset.submit}`).submit();
                }
            })
    }
}