import {Controller} from "stimulus"
import $ from 'jquery';
import 'jquery-datetimepicker';
import {get} from '@rails/request.js'

import flatpickr from "flatpickr";
import {Spanish} from "flatpickr/dist/l10n/es";

require("select2/dist/css/select2")
require("select2-bootstrap-theme/dist/select2-bootstrap")
import select2 from "select2"

export let check_availability = (params, obj) => new Promise((resolve, reject) => {
    $.ajax({
        url: `${location.origin}/check-availability`,
        data: params,
        type: 'POST',
        beforeSend: () => {
            obj.innerHTML = `<span class="fa fa-spinner fa-spin"></span>`;
            $(obj).prop('disabled', true);
        },
        success: (data) => {
            resolve(data);
        }
    })

})

let add_to_date = (year, month, day, hours, minute, min = 0, delay = 0, max = 0, hours_add = 1, server_date = null) => new Promise((resolve, reject) => {
    let calc_hours = parseInt(hours) + hours_add;
    if (`${calc_hours}`.length === 1) {
        calc_hours = `0${calc_hours}`;
    }
    let date = new Date(`${year}-${month}-${day}T${calc_hours}:00:00`);
    // Convertir la diferencia a horas dividiendo por 60
    let diferenciaHoras = date.getTimezoneOffset() / 60;
    let miliseconds_hours = diferenciaHoras * 3600000;

    let start_date = new Date(`${year}-${month}-${day}T${calc_hours}:00:00`).getTime();
    start_date = new Date(start_date - miliseconds_hours);

    let from_server_now = null;
    let min_date = new Date(`${year}-${month}-${day}T${calc_hours}:00:00`).getTime();
    min_date = new Date(min_date - miliseconds_hours);
    let max_date = null;
    let min_time = '00:00'
    start_date = start_date.setDate(start_date.getDate() + delay);
    start_date = new Date(start_date);
    min_date = min_date.setDate(min_date.getDate() + min);
    min_date = new Date(min_date);


    if (max > 0) {
        max_date = new Date(`${year}-${month}-${day}T${calc_hours}:00:00`);
        max_date = new Date(max_date - miliseconds_hours);
        max_date = max_date.setDate(max_date.getDate() + max);
        max_date = new Date(max_date);

        remake_date(max_date.toISOString()).then(max => {
            max_date = max;
        });
    }
    remake_date(min_date.toISOString()).then(
        min => {
            min_date = min;
        }
    )
    remake_date(start_date.toISOString()).then(
        start => {
            start_date = start;
        }
    )
    if (server_date !== null) {
        let calc_hours_from_server = parseInt(server_date[3]);
        if (`${calc_hours_from_server}`.length === 1) {
            calc_hours_from_server = `0${calc_hours_from_server}`;
        }
        from_server_now = new Date(`${server_date[0]}-${server_date[1]}-${server_date[2]}T${calc_hours_from_server}:00:00`);
        remake_date(from_server_now.toISOString()).then(
            from => {
                from_server_now = from;
            }
        )
    }

    setTimeout(() => {
        resolve({
            min: min_date,
            start: start_date,
            max: max_date,
            from_server: from_server_now
        })
    }, 500)
})
let remake_date = (date) => new Promise((resolve, reject) => {
    let date_string = date.split('T')[0];
    let hour_string = date.split('T')[1];

    let minified_year = date.split('T')[0].split('-');
    minified_year[0] = minified_year[0].slice(2, 4);
    minified_year = minified_year.reverse().join('/');

    date_string = date_string.split('-');
    date_string = date_string.reverse().join('/')

    hour_string = hour_string.replace(':00.000Z', '');
    resolve({
        date: date_string,
        minified: minified_year,
        time: hour_string
    });
})
let get_hours_office = (office) => new Promise((resolve, reject) => {

    $.ajax({
        url: `${location.origin}/admin/offices/${office}/get_office`,
        data: {format: 'json'},
        type: 'GET',
        success: (data) => {
            resolve(data);
        }
    })
})
export default class extends Controller {
    static targets = ["agencySelect", "destiationsSelect", "dealerSelect", "officeSelect", "productSelect", "productDetails", "additionalData", "additionalDrivers", "contractSelect", "modalitySelect", "tariffSelect", "departuresField", "departuresHoursField", "dropField", "dropHoursField", "priceDetails", "airport", "userMessage", "responsabilitySupply", "paxAirportSelect"]

    initialize() {
        setTimeout(() => {

            let context = this;
            this.show_agency_fields = (this.element.dataset.showFields === "true");
            this.count_dealer_options = [];
            this.own_product = false;
            this.not_own_product_area = $('#not-own-product');
            this.hours_diference = 72;
            $.datetimepicker.setLocale('es');

            // OFICINAS
            if (this.hasOfficeSelectTarget) {
                $(this.officeSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción"
                }).prop('disabled', true).on('select2:select', function () {
                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                })
            }
            // MODALIDADES
            if (this.hasModalitySelectTarget) {
                $(this.modalitySelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                }).prop('disabled', true).on('select2:select', function () {

                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {

                }).on('select2:select', function () {});
            }

            // TARIFAS
            if (this.hasTariffSelectTarget) {
                $(this.tariffSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                }).prop('disabled', true).on('select2:select', function () {

                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {

                });
            }
            // CONTRATOS
            if (this.hasContractSelectTarget) {
                $(this.contractSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                }).prop('disabled', true).on('change.select2', () => {
                    if (this.contractSelectTarget.options.length === 1) {
                        if (this.own_product) {
                            $(context.tariffSelectTarget)[0].classList.remove('required');
                            $(context.tariffSelectTarget).prop('disabled', true);
                            $(context.tariffSelectTarget).empty();

                            $(context.modalitySelectTarget)[0].classList.remove('required');
                            $(context.modalitySelectTarget).prop('disabled', true);
                            $(context.modalitySelectTarget).empty();
                        } else {
                            this.getTariffs();
                            $(context.tariffSelectTarget).removeAttr('disabled');
                            $(context.modalitySelectTarget).removeAttr('disabled');
                            $(context.tariffSelectTarget)[0].classList.add('required');
                            $(context.modalitySelectTarget)[0].classList.add('required');
                        }
                    } else {
                        if (this.own_product) {
                            $(context.tariffSelectTarget)[0].classList.remove('required');
                            $(context.tariffSelectTarget).prop('disabled', true);
                            $(context.tariffSelectTarget).empty();

                            $(context.modalitySelectTarget)[0].classList.remove('required');
                            $(context.modalitySelectTarget).prop('disabled', true);
                            $(context.modalitySelectTarget).empty();
                        }
                    }
                }).on('select2:select', function () {
                    $(context.tariffSelectTarget).removeAttr('disabled');
                    $(context.tariffSelectTarget).empty();
                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {
                    $(context.tariffSelectTarget).prop('disabled', true);
                    $(context.tariffSelectTarget).empty();
                });
            }
            // PRESTATARIOS
            if (this.hasDealerSelectTarget) {
                let dealerSelect2 = $(this.dealerSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                });


                dealerSelect2.prop('disabled', true).on('change.select2', () => {
                    if (this.dealerSelectTarget.options.length === 1) {
                        context.getOffices();
                        context.getContracts();
                        $(context.officeSelectTarget).removeAttr('disabled');
                        $(context.contractSelectTarget).removeAttr('disabled');
                    }
                }).on('select2:select', function () {
                    $(context.contractSelectTarget).removeAttr('disabled');
                    $(context.contractSelectTarget).empty();
                    $(context.officeSelectTarget).removeAttr('disabled');
                    $(context.officeSelectTarget).empty();
                    context.getContracts();
                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {
                    $(context.contractSelectTarget).prop('disabled', true);
                    $(context.contractSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                });


            }
            // DESTINOS
            if (this.hasDestiationsSelectTarget) {
                $(this.destiationsSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                }).prop('disabled', this.show_agency_fields).on('select2:select', function () {
                    $(context.dealerSelectTarget).removeAttr('disabled');
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {
                    $(context.dealerSelectTarget).prop('disabled', true);
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                }).on('change.select2', function () {
                    $(context.dealerSelectTarget).removeAttr('disabled');
                    $(context.dealerSelectTarget).empty();
                    context.getDealers(true);
                    context.getAditionalData();
                });

            }
            // PRODUCTOS
            if (this.hasProductSelectTarget) {
                $(this.productSelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                }).prop('disabled', true).on('change.select2', () => {
                    // if (this.productSelectTarget.options.length === 1) {
                    this.getModalities(true);
                    this.getDestination();
                    this.getProductDataForSeason();
                    $(context.modalitySelectTarget).removeAttr('disabled');
                    $(context.destiationsSelectTarget).removeAttr('disabled');

                    // }
                }).on('select2:select', function () {
                    $(context.modalitySelectTarget).removeAttr('disabled');
                    $(context.modalitySelectTarget).empty();
                    $(context.destiationsSelectTarget).removeAttr('disabled');
                    $(context.destiationsSelectTarget).empty();

                    $(context.tariffSelectTarget).prop('disabled', true);
                    $(context.tariffSelectTarget).empty();
                    $(context.dealerSelectTarget).prop('disabled', true);
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                    context.getProductsDetails(true, true, false, true, true)
                    context.getProductDataForSeason();
                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {
                    $(context.destiationsSelectTarget).prop('disabled', true);
                    $(context.destiationsSelectTarget).empty();
                    $(context.tariffSelectTarget).prop('disabled', true);
                    $(context.tariffSelectTarget).empty();
                    $(context.modalitySelectTarget).prop('disabled', true);
                    $(context.modalitySelectTarget).empty();
                    $(context.dealerSelectTarget).prop('disabled', true);
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                });
            }
            // AGENCIAS
            if (this.hasAgencySelectTarget) {
                let agencySelect2 = $(this.agencySelectTarget).select2({
                    theme: "bootstrap",
                    language: "es",
                    placeholder: "Seleccione una opción",
                    allowClear: true
                });

                // Contar cantidad de options del select de agencias
                let count_options = [];
                $(this.agencySelectTarget).find('option').map((index, elem) => {
                    if (elem.value.length > 0) {
                        count_options.push(elem.value);
                    }
                })
                agencySelect2.on('change.select2', () => {

                    // if (count_options.length === 1) {
                    if (context.productSelectTarget.selectedOptions.length > 0 && context.productSelectTarget.selectedOptions[0].value !== '') {
                        this.getProductDataForSeason();
                    }

                    this.getProducts();
                    // $(this.agencySelectTarget).prop('disabled', true);
                    $(this.productSelectTarget).removeAttr('disabled');
                    // }
                }).on('select2:select', function () {
                    // SETEAR FECHAS
                    if (context.productSelectTarget.selectedOptions.length > 0 && context.productSelectTarget.selectedOptions[0].value !== '') {
                        context.getProductDataForSeason();
                    }

                    $(context.dealerSelectTarget).prop('disabled', true);
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                    $(context.productSelectTarget).removeAttr('disabled');
                    $(context.productSelectTarget).empty();
                    $(context.modalitySelectTarget).prop('disabled', true);
                    $(context.modalitySelectTarget).empty();

                    let event = new Event('change', {bubbles: true})
                    this.dispatchEvent(event);
                }).on('select2:unselecting', function () {
                    $(context.destiationsSelectTarget).prop('disabled', true);
                    $(context.destiationsSelectTarget).empty();
                    $(context.dealerSelectTarget).prop('disabled', true);
                    $(context.dealerSelectTarget).empty();
                    $(context.officeSelectTarget).prop('disabled', true);
                    $(context.officeSelectTarget).empty();
                    $(context.productSelectTarget).prop('disabled', true);
                    $(context.productSelectTarget).empty();
                    $(context.modalitySelectTarget).prop('disabled', true);
                    $(context.modalitySelectTarget).empty();
                    $(context.contractSelectTarget).prop('disabled', true);
                    $(context.contractSelectTarget).empty();
                    $(context.tariffSelectTarget).prop('disabled', true);
                    $(context.tariffSelectTarget).empty();
                })

                // Disparar evento change de el select de agencia si hay un solo option para seleccionar

                // if (count_options.length === 1) {
                agencySelect2.trigger({
                    type: 'change.select2'
                });
                // }
            }

        }, 2000)
    }

    connect() {
        let context = this;
        let departure = $(this.departuresFieldTarget);
        let departure_hours = $(this.departuresHoursFieldTarget);
        let dropField = $(this.dropFieldTarget);
        let add_hours = 1;

        this.date_from_server_now = this.element.dataset.now;
        this.date_from_server_now = this.date_from_server_now.split("|");

        if (this.departuresFieldTarget.dataset.value !== undefined) {
            let year_complete = `${new Date().getFullYear()}`.slice(0,2)
            add_hours = 0;
            this.date_from_server = this.departuresFieldTarget.dataset.value;
            this.date_from_server = this.date_from_server.split("/").reverse();
            this.date_from_server[0] = parseInt(year_complete)+''+this.date_from_server[0];
            let hours = this.departuresHoursFieldTarget.dataset.value.split(':');
            this.date_from_server.push(hours[0]);
            this.date_from_server.push(hours[1]);

            this.date_from_server_to = this.dropFieldTarget.dataset.value;
            this.date_from_server_to = this.date_from_server_to.split("/").reverse();
            this.date_from_server_to[0] = parseInt(year_complete)+''+this.date_from_server_to[0];
            let hours_to = this.departuresHoursFieldTarget.dataset.value.split(':');
            this.date_from_server_to.push(hours_to[0]);
            this.date_from_server_to.push(hours_to[1]);
        } else {
            this.date_from_server = this.element.dataset.now;
            this.date_from_server = this.date_from_server.split("|");
        }

        this.hours_diference = 48;
        let year = this.date_from_server[0];
        // this.date_now = new Date(`${this.date_from_server[0]}-${this.date_from_server[1]}-${this.date_from_server[2]}T${parseInt(this.date_from_server[3]) + 1}:00`);
        this.compare_date_now = new Date(`${this.date_from_server[0]}-${this.date_from_server[1]}-${this.date_from_server[2]}T${parseInt(this.date_from_server[3])}:00`);
        // dropField.prop('disabled', true);
        // FECHA DE RECOGIDA
        add_to_date(this.date_from_server[0], this.date_from_server[1], this.date_from_server[2], this.date_from_server[3], this.date_from_server[4], 0, 2, 0, add_hours, this.date_from_server_now).then(date => {
            if (this.departuresFieldTarget.dataset.value !== undefined) {
                this.setDepartureField(date.from_server, date.start, date.max);
            } else {
                this.setDepartureField(date.from_server, date.start, date.max);
            }

            this.setDepartureHoursField(date.from_server, date.start, date.max);
            this.setDropOffHoursField(date.start.time);
        })

        // FECHA DE ENTREGA
        if (this.departuresFieldTarget.dataset.value !== undefined) {
            add_to_date(this.date_from_server_to[0], this.date_from_server_to[1], this.date_from_server_to[2], this.date_from_server_to[3], this.date_from_server_to[4], 0, 0, 30).then(date_finish => {
                this.setDropOffField(date_finish.min, date_finish.min, date_finish.max);
            })
        } else {
            add_to_date(this.date_from_server[0], this.date_from_server[1], this.date_from_server[2], this.date_from_server[3], this.date_from_server[4], 2, 4, 30).then(date_finish => {
                this.setDropOffField(date_finish.min, date_finish.start, date_finish.max);
            })
        }

        departure.on('change', function (e) {
            let value = e.target.value;
            let date_split = value.split(' ');
            let date = date_split[0];
            let hours = context.departuresHoursFieldTarget.value;
            // dropField.removeAttr('disabled');

            // COMPARAR LA FECHA SELECIONADA CON LA FECHA ACTUAL PARA VERIFICAR QUE LA RESERVA SE HAGA CON MAS DE 48 HORAS DE ANTELACIÓN
            let selected_date = date.split('/')
            selected_date[2] = year;
            selected_date = selected_date.reverse().join('-')
            let date_finish = new Date(`${selected_date}T${hours}:00`)
            // Calcular la diferencia en milisegundos
            let diferenciaMilisegundos = date_finish - context.compare_date_now;
            // Convertir la diferencia a horas
            let diferenciaHoras = diferenciaMilisegundos / (1000 * 60 * 60);
            this.hours_diference = diferenciaHoras;
            context.getProductDataForSeason('departure', null, true);
            context.getProductsDetails(false, true, true);
            context.getProductDataForSeason('drop', null, true);
        });

        departure_hours.on('change', function (e) {
            context.setDropOffHoursField(e.target.value)
            context.getProductsDetails(false, true, true);
        })
        dropField.on('change', function (e) {
            context.getProductsDetails(false, true, true);
        })

        $(`#${this.additionalDriversTarget.id}`).on('change', function (e) {
            context.getProductsDetails(false, true, true);
        })
    }

    setDepartureField(minDate, startDate, maxDate) {
        let dep_input = $(`#${this.departuresFieldTarget.id}`);
        dep_input.val(`${startDate.minified}`);

        flatpickr(`#${this.departuresFieldTarget.id}`, {
            altInput: true,
            altFormat: "d/m/y",
            dateFormat: "d/m/y",
            defaultDate: `${startDate.minified} `,
            minDate: `${minDate.minified}`,
            disableMobile: "true",
            locale: Spanish
        });
    }

    setDepartureHoursField(minDate, startDate, maxDate) {
        if (maxDate == null) {
            maxDate = {};
            maxDate.time = '23:50';
        }

        flatpickr(`#${this.departuresHoursFieldTarget.id}`, {
            altFormat: "H:i",
            dateFormat: "H:i",
            enableTime: true,
            noCalendar: true,
            defaultDate: `${startDate.time}`,
            /*minTime: `${minDate.time}`,
            maxTime: `${maxDate.time}`,*/
            minuteIncrement: 15,
            disableMobile: "true",
            locale: Spanish
        });
    }

    setDropOffField(minDate, startDate, maxDate) {
        let drop_input = $(`#${this.dropFieldTarget.id}`);

        flatpickr(`#${this.dropFieldTarget.id}`, {
            altInput: true,
            altFormat: "d/m/y",
            dateFormat: "d/m/y",
            defaultDate: `${minDate.minified}`,
            minDate: `${minDate.minified}`,
            maxDate: `${maxDate.minified}`,
            disableMobile: "true",
            locale: Spanish
        });
        //
        // drop_input.val(`${startDate.minified}`);
    }

    setDropOffHoursField(time) {
        let drop_input = $(`#${this.dropHoursFieldTarget.id}`);
        drop_input.val(`${time}`);
    }

    getDestination() {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let target = this.destiationsSelectTarget.id
        get(`/admin/get_destinations`, {
            query: {
                id: agency,
                product: product,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            setTimeout(() => {
                if (this.destiationsSelectTarget.dataset.value !== undefined) {
                    $(this.destiationsSelectTarget).removeAttr('disabled');
                    $(this.destiationsSelectTarget).val(this.destiationsSelectTarget.dataset.value); // Change the value or make some change to the internal state
                    $(this.destiationsSelectTarget).trigger('change.select2');
                }
            }, 500)
        })
    }

    getDealers(initial_load = false) {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let destination = this.destiationsSelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let target = this.dealerSelectTarget.id

        get(`/admin/get_dealers`, {
            query: {
                id: agency,
                destination: destination,
                product: product,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            this.setDealerData(initial_load);
        })
    }

    setDealerData(initial_load) {
        if (initial_load) {
            setTimeout(() => {
                if (this.dealerSelectTarget.dataset.value !== undefined) {
                    $(this.dealerSelectTarget).removeAttr('disabled');
                    $(this.dealerSelectTarget).val(this.dealerSelectTarget.dataset.value); // Change the value or make some change to the internal state
                    $(this.dealerSelectTarget).trigger('change.select2');
                }
            }, 500)
        }
    }

    getOffices() {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let destination = this.destiationsSelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let dealer = this.dealerSelectTarget.selectedOptions[0].value;
        let target = this.officeSelectTarget.id

        get(`/admin/get_offices`, {
            query: {
                id: dealer,
                destination: destination,
                product: product,
                agency: agency,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            setTimeout(() => {
                if (this.officeSelectTarget.dataset.value !== undefined) {
                    $(this.officeSelectTarget).removeAttr('disabled');
                    $(this.officeSelectTarget).val(this.officeSelectTarget.dataset.value); // Change the value or make some change to the internal state
                    $(this.officeSelectTarget).trigger('change.select2');
                }
            }, 500)
        })
    }

    getOfficeById() {
        this.getProductDataForSeason('departure')
    }

    getProducts() {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let target = this.productSelectTarget.id

        get(`/admin/get_products`, {
            query: {
                agency: agency,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            this.setProductData();
        })
    }

    setProductData() {
        setTimeout(() => {
            if (this.productSelectTarget.dataset.value !== undefined) {
                $(this.productSelectTarget).removeAttr('disabled');
                $(this.productSelectTarget).val(this.productSelectTarget.dataset.value); // Change the value or make some change to the internal state
                $(this.productSelectTarget).trigger('change.select2');
            }
        }, 500)
    }

    getProductsDetails(show_load = true, refresh_details = true, refresh_price = true, refresh_message = false, show_description = false) {
        let id_voucher = ''
        if ($('#voucher_id') !== undefined) {
            id_voucher = $('#voucher_id').val();
        }
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let destination = this.destiationsSelectTarget.value;
        let oficina = this.officeSelectTarget.value;
        let product = this.productSelectTarget.selectedOptions[0].value
        let target = this.productDetailsTarget.id
        let price = this.priceDetailsTarget.id
        let departure = `${this.departuresFieldTarget.value} ${this.departuresHoursFieldTarget.value}`
        let drop = `${this.dropFieldTarget.value} ${this.dropHoursFieldTarget.value}`
        let user_message_area = this.userMessageTarget.id
        let add_airport = false
        let pax_airport = '1'
        if (this.hasPaxAirportSelectTarget) {
            pax_airport = this.paxAirportSelectTarget.value
        }
        let term_and_condition = 'term_and_conditions';
        let additionalDrivers = this.additionalDriversTarget.id;
        let additionalDriversValue = this.additionalDriversTarget.selectedOptions[0].value;

        if (this.hasAirportTarget) {
            add_airport = this.airportTarget.checked
        }

        if (show_load) {
            this.productDetailsTarget.innerHTML = "<span class='fa fa-circle-o-notch fa-spin fa-2x' style='color: var(--first-color)'></span>"
        }


        get(`/admin/get_products_details`, {
            query: {
                id: id_voucher,
                agency: agency,
                destination: destination,
                office: oficina,
                product: product,
                target: target,
                price: price,
                departure: departure,
                drop: drop,
                refresh_details: refresh_details,
                refresh_price: refresh_price,
                refresh_message: refresh_message,
                add_airport: add_airport,
                pax_airport: pax_airport,
                show_description: show_description,
                user_message_area: user_message_area,
                additionalDrivers: additionalDrivers,
                additionalDriversValue: additionalDriversValue,
                term_and_condition: term_and_condition,
                has_terms: false
            },
            responseKind: "turbo-stream"
        }).then()
    }

    getProductDataForSeason(type = '', less_day = null, show_price = false) {
        let context = this;
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let departure = `${this.departuresFieldTarget.value} ${this.departuresHoursFieldTarget.value}`
        let drop = `${this.dropFieldTarget.value} ${this.dropHoursFieldTarget.value}`
        // let checkAvailability = document.querySelector('#checkAvailability');
        // let noCheckAvailability = document.querySelector('#noCheckAvailability');
        // let terms_create_reservation = document.querySelector('#terms-create-reservation');
        // let terms_request_reservation = document.querySelector('#terms-request-reservation');
        // let create_reservation = document.querySelector('#create-reservation');
        // let request_reservation = document.querySelector('#request-reservation');

        $.ajax({
            url: `/admin/get_products_data_for_season`,
            data: {
                agency: agency,
                product: product,
                departure: departure,
                drop: drop,
            },
            type: "GET",
            success: (data) => {
                this.own_product = data.own_product;

                if (this.not_own_product_area.length > 0) {
                    if (data.own_product) {
                        if (this.not_own_product_area.length > 0) {
                            this.not_own_product_area[0].classList.add('ocultar');
                        }
                        // checkAvailability.classList.add('d-none');
                        // noCheckAvailability.classList.remove('d-none')
                        // RELEVO DE RESPONSABILIDAD
                        if (this.hasResponsabilitySupplyTarget) {
                            this.responsabilitySupplyTarget.classList.add('ocultar');
                        }

                    } else {
                        if (this.not_own_product_area.length > 0) {
                            this.not_own_product_area[0].classList.remove('ocultar');
                        }
                        // RELEVO DE RESPONSABILIDAD
                        if (this.hasResponsabilitySupplyTarget) {
                            this.responsabilitySupplyTarget.classList.remove('ocultar');
                        }
                    }
                }

                let split_date = data.date.split(' ');
                let date_selected = split_date[0].split('/');
                let hours_selected = split_date[1].split(':');
                if (`${hours_selected[0]}`.length === 1) {
                    hours_selected[0] = "00";
                }

                switch (type) {
                    case 'departure':
                        let date_finish2 = new Date(`${data.year}-${date_selected[1]}-${date_selected[0]}T${hours_selected[0]}:00`)
                        // Calcular la diferencia en milisegundos
                        let diferenciaMilisegundos2 = date_finish2 - this.compare_date_now;
                        // Convertir la diferencia a horas
                        let diferenciaHoras2 = diferenciaMilisegundos2 / (1000 * 60 * 60);
                        let set_hours2 = hours_selected[0]
                        let oficina = this.officeSelectTarget.value;


                        get_hours_office(oficina).then(res => {
                            if (res.full_time) {
                                if (diferenciaHoras2 > 49) {
                                    set_hours2 = "00"
                                } else {
                                    set_hours2 = parseInt(new Date().getHours()) + 1;
                                }
                                add_to_date(data.year, date_selected[1], date_selected[0], set_hours2, hours_selected[1], parseInt(data.min), parseInt(data.delay), parseInt(data.max), 0, context.date_from_server_now).then(date => {
                                    this.setDepartureHoursField(date.from_server, date.start, null);
                                    this.setDropOffHoursField(date.start.time);
                                });
                            } else {
                                let open = {
                                    time: res.open
                                };
                                let close = {
                                    time: res.close
                                };

                                if (diferenciaHoras2 > 49) {
                                    set_hours2 = res.open.split(':')[0]
                                } else {
                                    set_hours2 = parseInt(new Date().getHours()) + 1;
                                    open.time = `${(parseInt(new Date().getHours()) + 1)}:00`
                                }

                                add_to_date(data.year, date_selected[1], date_selected[0], set_hours2, hours_selected[1], parseInt(data.min), parseInt(data.delay), parseInt(data.max), 0).then(date => {
                                    this.setDepartureHoursField(open, date.start, close);
                                    this.setDropOffHoursField(date.start.time);
                                });
                            }
                        });

                        break;
                    case 'drop':
                        let delay = data.season ? 0 : 1
                        add_to_date(data.year_selected, date_selected[1], date_selected[0], hours_selected[0], hours_selected[1], parseInt(data.min), parseInt(data.delay), parseInt(data.max), 0).then(date => {
                            this.setDropOffField(date.min, date.start, date.max);
                            setTimeout(() => {
                                this.getProductsDetails(false, false, true);
                            }, 500);
                        });
                        if (show_price) {
                            setTimeout(() => {
                                this.getProductsDetails(false, true, true);
                            }, 500);
                        }
                        break;
                    default:
                        let min_back = 0;
                        if (data.own_product) {
                            min_back = data.min;
                        }

                        let date_finish = new Date(`${this.date_from_server[0]}-${this.date_from_server[1]}-${this.date_from_server[2]}T${parseInt(this.date_from_server[3]) + 1}:00`)
                        date_finish = date_finish.setDate(date_finish.getDate() + data.delay);
                        date_finish = new Date(date_finish);
                        // Calcular la diferencia en milisegundos
                        let diferenciaMilisegundos = date_finish - this.compare_date_now;
                        // Convertir la diferencia a horas
                        let diferenciaHoras = diferenciaMilisegundos / (1000 * 60 * 60);
                        if (this.departuresFieldTarget.dataset.value !== undefined) {
                            show_price = true
                            add_to_date(this.date_from_server[0], this.date_from_server[1], this.date_from_server[2], this.date_from_server[3], this.date_from_server[4], 0, 2, 30 || null, 0, context.date_from_server_now).then(date => {
                                this.setDepartureField(date.from_server, date.min, date.max);
                            });
                            add_to_date(this.date_from_server_to[0], this.date_from_server_to[1], this.date_from_server_to[2], this.date_from_server_to[3], this.date_from_server_to[4], 0, 0, 30, 0).then(date => {

                                this.setDropOffField(date.min, date.start, date.max);
                                if (product.length > 0 && show_price) {
                                    setTimeout(() => {

                                        this.getProductsDetails(true, true, true);
                                    }, 500);
                                }
                            });
                            break;
                        } else {
                            add_to_date(this.date_from_server[0], this.date_from_server[1], this.date_from_server[2], this.date_from_server[3], this.date_from_server[4], (parseInt(data.delay) - min_back) || 0, parseInt(data.delay) || 2, parseInt(data.max) || null, 1, context.date_from_server_now).then(date => {
                                this.setDepartureField(date.from_server, date.start, date.max);
                            });
                            add_to_date(this.date_from_server[0], this.date_from_server[1], this.date_from_server[2], this.date_from_server[3], this.date_from_server[4], (parseInt(data.min) + parseInt(data.delay)) || 3, (parseInt(data.min) + parseInt(data.delay)) || 3, parseInt(data.max) || null, 1).then(date => {
                                this.setDropOffField(date.min, date.start, date.max);

                                if (product.length > 0 && show_price) {
                                    setTimeout(() => {

                                        this.getProductsDetails(false, false, true);
                                    }, 500);
                                }
                            });
                            break;
                        }
                }
            }
        })
    }

    getAditionalData() {
        let id_voucher = ''
        if ($('#voucher_id') !== undefined) {
            id_voucher = $('#voucher_id').val();
        }
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value
        let destination = this.destiationsSelectTarget.value;
        let target = this.additionalDataTarget.id

        get(`/admin/get_products_additional`, {
            query: {
                id: id_voucher,
                agency: agency,
                destination: destination,
                product: product,
                target: target,
                edit: true
            },
            responseKind: "turbo-stream"
        }).then()
    }

    getModalities(initial_load) {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let target = this.modalitySelectTarget.id

        get(`/admin/get_modalities`, {
            query: {
                agency: agency,
                product: product,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            this.setModalityData(initial_load);
        })
    }

    setModalityData(initial_load) {
        if (initial_load) {
            setTimeout(() => {
                if (this.modalitySelectTarget.dataset.value !== undefined) {
                    $(this.modalitySelectTarget).removeAttr('disabled');
                    $(this.modalitySelectTarget).val(this.modalitySelectTarget.dataset.value); // Change the value or make some change to the internal state
                    $(this.modalitySelectTarget).trigger('change.select2');
                }
            }, 500)
        }
    }

    getContracts() {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let product = this.productSelectTarget.selectedOptions[0].value;
        let dealer = (this.dealerSelectTarget.selectedOptions.length > 0) ? (this.dealerSelectTarget.selectedOptions[0].value || "") : "";
        let target = this.contractSelectTarget.id

        get(`/admin/get_contracts`, {
            query: {
                agency: agency,
                product: product,
                dealer: dealer,
                target: target
            },
            responseKind: "turbo-stream"
        }).then(r => {
            this.setContractData();
        })
    }

    setContractData() {
        setTimeout(() => {
            if (this.contractSelectTarget.dataset.value !== undefined) {
                $(this.contractSelectTarget).removeAttr('disabled');
                $(this.contractSelectTarget).val(this.contractSelectTarget.dataset.value); // Change the value or make some change to the internal state
                $(this.contractSelectTarget).trigger('change.select2');
            }
        }, 500)
    }

    getTariffs() {
        let agency = this.agencySelectTarget.selectedOptions[0].value;
        let contract = this.contractSelectTarget.selectedOptions[0].value;
        let target = this.tariffSelectTarget.id

        get(`/admin/get_tariffs`, {
            query: {
                agency: agency,
                contract: contract,
                target: target
            },
            responseKind: "turbo-stream"
        }).then()
    }

    setInputRequired(e) {
        let target = $(`#${e.target.dataset.target}`);
        let inputs_field = target.find('input');
        target.toggleClass('ocultar');
        inputs_field.map((index, elem) => {
            $(elem).toggleClass('required');
        })
    }
}

